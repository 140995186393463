import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import Activity from "components/common/activity";
import Attachment from "components/common/attachment";
import Chat from "components/common/chat";
import Email from "components/common/email";
import Invoice from "components/common/invoice";
import Note from "components/common/note";
import Task from "components/common/task";
import NotAuthorized from "components/notAuthorized";
import RightDrawer from "components/rightDrawer/RightDrawer";
import useBase from "hooks/useBase";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Embassy from "./Embassy";
import Admission from "./admission/Admission";
import Expense from "./expense/Expense";
import Profile from "./profile/Profile";
import Sponsor from "./sponsor/Sponsor";
import { project_customer_alart } from "features/projectCustomers/api/projectCustomer";
import useQueries from "hooks/useQueries";
import useApi from "hooks/useApi";
import Payment from "./payment/Payment";

const ViewCustomer = ({
  openDrawer,
  setOpenDrawer,
  assignees,
  allCustomers,
  setAllCustomers,
  permissions,
}) => {
  const { row_id, mod_id, setQueries } = useQueries();
  const [alertsCount, setAlertsCount] = useState({});
  let user_role = useSelector((state) => state?.auth?.user_details?.role);
  const user_id = useSelector((state) => state.auth.user_details?.user?.id);
  const { base } = useBase();
  const activeEmailModules = base?.settings?.email?.active_modules;
  const { loading, fetchData } = useApi();

  const [profile, setProfile] = useState({});
  const [value, setValue] = useState(mod_id || "1");
  const handleChange = (event, newValue) => {
    setQueries(`id=${row_id}&m=${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/project-customer/show/${row_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setProfile(data);
    };
    const getWarnsCount = async () => {
      const res = await project_customer_alart({ id: row_id });
      if (res.success) setAlertsCount(res.data);
    };
    getProfile();
    getWarnsCount();
  }, []);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "1250px",
        headingLeft:
          "#" +
          row_id +
          `${profile?.customer_name ? ` - ${profile?.customer_name}` : ""}`,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab label="Profile" value="1" />
              <Tab label="Admission" value="5" />
              <Tab
                label="Sponsor"
                value="6"
                style={{
                  backgroundColor: alertsCount?.sponsor_alert
                    ? "#fff1f1"
                    : "white",
                }}
              />
              <Tab
                label="Embassy"
                value="7"
                style={{
                  backgroundColor: alertsCount?.embassy_alert
                    ? "#fff1f1"
                    : "white",
                }}
              />
              <Tab
                label="Invoice"
                value="8"
                style={{
                  backgroundColor: alertsCount?.invoice_alert
                    ? "#fff1f1"
                    : "white",
                }}
              />
              <Tab label="Payment" value="9" />
              <Tab label="Chat" value="10" />
              <Tab label="Expense" value="11" />
              {activeEmailModules?.includes("project") && (
                <Tab label="Email" value="12" />
              )}
              <Tab label="Activity" value="13" />
              <Tab label="Attachment" value="2" />
              <Tab label="Note" value="3" />
              <Tab label="Task" value="4" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1" sx={{ p: 0 }}>
              {permissions["show"] ? (
                <Profile profile={profile} loading={loading} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>

            <TabPanel sx={{ paddingBottom: "0px" }} value="5">
              {permissions["task"] ? (
                <Admission
                  project_customer_id={row_id}
                  assignees={assignees}
                  customer_id={profile?.customer_id}
                  project_id={profile?.project_id}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel sx={{ paddingBottom: "0px" }} value="6">
              {permissions["task"] ? (
                <Sponsor
                  id={row_id}
                  customer_id={profile?.customer_id}
                  project_id={profile?.project_id}
                  project_customer_id={row_id}
                  assignees={assignees}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="7">
              <Embassy
                id={row_id}
                customer_id={profile?.customer_id}
                project_id={profile?.project_id}
                project_customer_id={row_id}
              />
            </TabPanel>
            <TabPanel value="8">
              {permissions["invoice"] ? (
                !loading && (
                  <Invoice
                    reference_id={profile?.reference_id}
                    customer_id={
                      (!!permissions["project invoice"] ||
                        !!permissions["all invoice"]) &&
                      profile?.customer_id
                    }
                    project_id={
                      !!permissions["project invoice"] &&
                      user_role !== "Admin" &&
                      profile?.project_id
                    }
                    assignees={profile?.assignees?.map((a) => a?.user_id)}
                    intake_id={profile?.intake_id}
                    permissions={permissions}
                    module_name="project-customer"
                  />
                )
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="9">
              {permissions["invoice"] || true ? (
                <Payment
                  customer_id={profile?.customer_id}
                  project_id={profile?.project_id}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="10">
              <Box sx={{ m: "-20px" }}>
                <Chat
                  projectInfo={{
                    related_id: row_id,
                    related_to: "project",
                    project_id: row_id,
                  }}
                  route={"admin"}
                  user_id={user_id}
                  owner_id={user_id}
                  projectName={profile?.project_name}
                  matchHeight="142px"
                />
              </Box>
            </TabPanel>
            <TabPanel value="11">
              {permissions["invoice"] || true ? (
                <Expense
                  customer_id={profile?.customer_id}
                  project_id={profile?.project_id}
                  reference_id={profile?.reference_id}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="12">
              <Email
                email_addresses={[profile?.customer_email || ""]}
                related_to="project"
                related_id={profile?.id}
              />
            </TabPanel>
            <TabPanel value="13">
              {permissions["activity"] ? (
                <Activity
                  activityList={{
                    method: "get",
                    url: `/api/admin/project-customer/activity/${row_id}`,
                  }}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>

            <TabPanel value="2">
              {permissions["attachment"] ? (
                <Attachment id={row_id} route="project-customer" />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="3">
              {permissions["note"] ? (
                <Note
                  id={row_id}
                  related_id={row_id}
                  related_to="project"
                  table_note_key="notes"
                  route="project-customer"
                  allData={allCustomers}
                  setAllData={setAllCustomers}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel sx={{ paddingBottom: "0px" }} value="4">
              {permissions["task"] ? (
                <Task
                  id={row_id}
                  route="project-customer"
                  related_to="project"
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewCustomer;
